import { MenuEntry } from '@encodix/huliinfo'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  {
    label: t('Home'),
    icon: 'HomeIcon',
    href: '#',
  },
  {
    label: t('Trade'),
    icon: 'TradeIcon',
    items: [
      {
        label: t('Exchange'),
        href: 'https://dex.hulihuli.finance/#/swap',
      },
      {
        label: t('Liquidity'),
        href: 'https://dex.hulihuli.finance/#/pool',
      },

    ],
  },
  // {
  //   label: t('Farms'),
  //   icon: 'FarmIcon',
  //   href: 'https://farms.hulihuli.finance/#/farms',
  // },
  // {
  //   label: t('Pools'),
  //   icon: 'PoolIcon',
  //   href: 'https://farms.hulihuli.finance/#/pools',
  // },
  {
    label: t('Info'),
    icon: 'InfoIcon',
    href: '/',
  },
  {
    label: t('Listings'),
    icon: 'NftIcon',
    items: [
      {
        label: 'BscScan',
        href: 'https://bscscan.com/',
      },
    ]
  },
  // {
  //   label: t('Prediction (BETA)'),
  //   icon: 'PredictionsIcon',
  //   href: 'https://farms.hulihuli.finance/prediction',
  // },
  // {
  //   label: t('Lottery'),
  //   icon: 'TicketIcon',
  //   href: 'https://farms.hulihuli.finance/lottery',
  // },
  // {
  //   label: t('Collectibles'),
  //   icon: 'NftIcon',
  //   href: 'https://farms.hulihuli.finance/collectibles',
  // },
  // {
  //   label: t('Team Battle'),
  //   icon: 'TeamBattleIcon',
  //   href: 'https://farms.hulihuli.finance/competition',
  // },
  // {
  //   label: t('Teams & Profile'),
  //   icon: 'GroupsIcon',
  //   items: [
  //     {
  //       label: t('Leaderboard'),
  //       href: 'https://farms.hulihuli.finance/teams',
  //     },
  //     {
  //       label: t('Task Center'),
  //       href: 'https://farms.hulihuli.finance/profile/tasks',
  //     },
  //     {
  //       label: t('Your Profile'),
  //       href: 'https://farms.hulihuli.finance/profile',
  //     },
  //   ],
  // },
  // {
  //   label: t('IFO'),
  //   icon: 'IfoIcon',
  //   href: 'https://farms.hulihuli.finance.finance/ifo',
  // },
  {
    label: t('More'),
    icon: 'MoreIcon',
    items: [
      {
        label: t('Contact'),
        href: 'https://docs.farms.hulihuli.finance/contact-us',
      },
      // {
      //   label: t('Voting'),
      //   href: 'https://voting.farms.hulihuli.finance',
      // },
      {
        label: t('Github'),
        href: 'https://github.com/hulihuli',
      },
      // {
      //   label: t('Docs'),
      //   href: 'https://docs.farms.hulihuli.finance',
      // },
      {
        label: t('Blog'),
        href: 'https://hulihuli.medium.com',
      },
      // {
      //   label: t('Merch'),
      //   href: 'https://egonswap.creator-spring.com/',
      // },
    ],
  },
]

export default config
