import React from 'react'
import styled from 'styled-components'
import CurrencyLogo from '../CurrencyLogo'

const Wrapper = styled.div<{ margin: boolean; sizeraw: number }>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 32px;
  margin-right: ${({ sizeraw, margin }) => margin && `${(sizeraw / 3 + 8).toString()}px`};
`

interface DoubleCurrencyLogoProps {
  margin?: boolean
  size?: number
  address0?: string
  address1?: string
}

const HigherLogo = styled(CurrencyLogo)`
  z-index: 2;
`
const CoveredLogo = styled(CurrencyLogo)<{ sizeraw: number }>`
  // position: absolute;
  // left: ${({ sizeraw }) => `-${(sizeraw / 2).toString()}px`} !important;
`

const DoubleCurrencyLogo: React.FC<DoubleCurrencyLogoProps> = ({ address0, address1, size = 16, margin = false }) => {
  return (
    <Wrapper sizeraw={size} margin={margin}>
      {address0 && <HigherLogo address={address0} size={`${size.toString()}px`} />}
      {address1 && <CoveredLogo address={address1} size={`${size.toString()}px`} sizeraw={size} />}
    </Wrapper>
  )
}

export default DoubleCurrencyLogo
